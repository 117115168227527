import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { Heading } from 'grommet';

const DynamicProductDescription = ({ blok: { product, ...rest } }) => {
  return get('item.title', product) ? (
    <Heading {...rest}>{get('item.title', product)}</Heading>
  ) : null;
};

DynamicProductDescription.propTypes = {
  blok: PropTypes.shape({
    product: PropTypes.object,
  }).isRequired,
};

export default DynamicProductDescription;
